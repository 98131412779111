.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 100%;
  margin: 0;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-section {
  margin: 100px 0;
  // background-color: #f5f6f8;
}

.swiper-section__title,
.charter-advantages{
    position: relative;
    margin-top: 200px;
    margin-bottom: 60px;
    text-align: center;
  
    &::before{
      content: '';
      position: absolute;
      top: -350%;
      left: 50%;
      right: 50%;
      height: 100px;
      width: 3px;
      background-color: black;
    }
  }

.swiper_content {
  width: 100%;
  margin: 0 auto !important;

  &--globe::before,
  &--globe::after {
    content: '';
    position: absolute;
    background-color: black;
    width: 3px;
    height: 100%;
  }

  &--globe::after {
    right: 0;
  }
}

.swiper-wrapper {
  align-items: center !important;
  // display: block;
  margin: 0 auto !important;
  gap: 10px;
}

.s-image {
  width: 50px !important;
  margin: 0 auto;
}

.swiper-section__item-title {
  font-size: 20px;
}


@media only screen and (max-width: 1000px) {
  .swiper-section__titleб,
  .charter-advantages{
    margin-top: 200px;
  }
}

@media only screen and (max-width: 991px) {
  .swiper-section__titleб,
  .charter-advantages{
    margin-top: 150px;
    &::before{
      top: -170%;
    }
  }
  .charter-advantages{
    &::before{
      top: -290%;
    }
  }
}

@media only screen and (max-width: 923px) {
  .swiper-section__titleб,
  .charter-advantages{
    &::before{
      top: -300%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .swiper-section__title,
  .charter-advantages{
    &::before{
      top: -180%;
    }
  }

  .charter-advantages{
    &::before{
      top: -330%;
    }
}

@media only screen and (max-width: 500px) {
  .swiper_content {

    &--globe::before,
    &--globe::after {
      display: none;
    }
  }
}

@media only screen and (max-width: 400px) {
  .swiper-section__titleб,
  .charter-advantages{
    font-size: 20px;
  }


  .swiper-section__titleб,
  .charter-advantages{
    margin-top: 150px;
    &::before{
      top: -250%;
    }
  }
  .charter-advantages{
    &::before{
      top: -500%;
    }}
}

// @media only screen and (max-width: 327px) {
//   // .swiper-section__titleб,

  
  
//   //   &::before{
//   //     content: '';
//   //     top: -140%;
//   //   }
//   // }
// }
}