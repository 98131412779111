.contacts{
  margin-top: 100px;
  
}

.contacts_title{
  position: relative;
  margin-top: 200px;
  margin-bottom: 60px;

  &::before{
    content: '';
    position: absolute;
    top: -350%;
    left: 50%;
    right: 50%;
    height: 100px;
    width: 3px;
    background-color: black;
  }
}

.contacts_maps{
  border-radius: 10px;
  border: #006ded solid 2px;
  width: 550px;
  height: 400px;
}

.contacts_email{
  width: 30px;
}

.contacts_text{
  font-size: 25px;
}



@media only screen and (max-width: 1000px) { 
.contacts_maps{
  width: 500px;
  height: 400px;
}
}

@media only screen and (max-width: 850px) { 
.contacts_maps{
  width: 400px;
  height: 400px;
}
}

@media only screen and (max-width: 600px) {
  .contacts_title{
    margin-top: 150px;
  
    &::before{
      content: '';
      top: -300%;
      height: 80px;
      width: 3px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .contacts_title{
  
    &::before{
      content: '';
      top: -340%;
    }
  }

  .contacts_maps{
    max-width: 100%;
  }

  .contacts_text{
    font-size: 20px;
  }

  .contacts_email{
    width: 20px;
  }
  
}