.about-us__title{
  position: relative;
  margin-top: 200px;
  margin-bottom: 60px;

  &::before{
    content: '';
    position: absolute;
    top: -350%;
    left: 50%;
    right: 50%;
    height: 100px;
    width: 3px;
    background-color: black;
  }
}

.about-us__numbers{
  color: #006ded;
  position: relative;

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 2px;
    background-color: #006ded;
  }
}

.about-us__text{
  color: #474747;
}

@media only screen and (max-width: 1000px) {
  .about-us__title{
    margin-top: 200px;
  }
}

@media only screen and (max-width: 1000px) {
  .about-us__numbers::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 2px;
      background-color: #006ded;
    }
}

@media only screen and (max-width: 600px) {
  .about-us__title{
    margin-top: 150px;
  
    &::before{
      content: '';
      top: -300%;
      height: 80px;
      width: 3px;
    }
  }
  .about-us__title{
    margin-top: 250px;
  }
}

@media only screen and (max-width: 500px) {
  .about-us__title{
  
    &::before{
      content: '';
      top: -340%;
    }
  }
}