.banner-image {
  background-image: url("../../assets/images/airbus.jpg");
  background-color: black;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;

  &::before{
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.479);
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.nav-bg {
  transition: 0.8s ease all;
  background-color: black;
}

  .header__links {
    overflow: hidden !important;
    position: relative !important;
    display: inline-block !important;
  }
  
  .header__links::before,
  .header__links::after {
   content: '';
    position: absolute !important;
    width: 100% !important;
    left: 0 !important;
  }
  .header__links::before {
    background-color: #54b3d6 !important;
    height: 2px !important;
    bottom: 0 !important;
    transform-origin: 100% 50% !important;
    transform: scaleX(0) !important;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1) !important;
  }
  .header__links::after {
    content: attr(data-replace) !important;
    height: 100% !important;
    top: 0 !important;
    transform-origin: 100% 50% !important;
    transform: translate3d(200%, 0, 0) !important;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1) !important;
    color: #54b3d6 !important;
  }
  
  .header__links:hover::before {
    transform-origin: 0% 50% !important;
    transform: scaleX(1) !important;
  }
  .header__links:hover::after {
    transform: translate3d(0, 0, 0) !important;
  }
  
  .header__links span {
    display: inline-block;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1) !important;
  }
  
  .header__links:hover span {
    transform: translate3d(-200%, 0, 0) !important;
  }
  
  .header__links {
    text-decoration: none ;
    color: #18272F ;
    font-weight: 700 ;
    vertical-align: top ;
  }


  .intro_text{
    padding: 0 60px;
    margin: 0;
  }

  .btn-appreance-mobile{
    display: none;      
  }

  @media only screen and (max-width: 991px) {
    .btn-appreance-mobile{
      display: block;
    }

    .btn-appreance{
      display: none;
    }

    .b-d{
      background-color: rgba(0, 0, 0, 0.637);
    }

    .header__logo{
      width: 60px;
    }
  }

  @media only screen and (max-width: 767px) {
    .header__logo{
      width: 50px;
    }

    .intro_text{
      font-size: 25px;
    }
  }

  @media only screen and (max-width: 390px) {
    .intro_text{
      font-size: 25px;
      padding: 0 20px;
    }
  }

  